.App {
    text-align: center;
  }
  
  .tab-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    width: 60%;
    margin-left: 300px;
  }
  
  .tab-button {
    background-color: #0554f2;
    color: white;
    border: none;
    padding: 14px 20px;
    cursor: pointer;
    border-radius: 4px;
    margin: 0 5px;
    transition: background-color 0.3s;
  }
  
  .tab-button:hover {
    background-color: #0554f2;
  }
  
  .tab-button.active {
    background-color: #0554f2;
  }
  
  .tab-content {
    background-color: white;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    margin-top: 20px;
    /* width:1200px;
    margin-left: 180px; */
  }



 @media (max-width: 600px) {
  .tab-container {
    flex-direction: column;
    align-items: center;
    margin-left: 70px;
    gap: 8px;
  }
  .tab-button {
    flex-basis: 100%; /* Full width for each button */
  }
}

@media (max-width: 400px) {
  .tab-content {
    padding: 10px;
  }
}




.training-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 600px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
}

.popup-image {
  width: 100%;
  height: auto;
  max-height: 350px;
  object-fit: cover;
}

@media (max-width: 600px) {
  .popup-content {
    padding: 15px;
  }
  .close-btn {
    font-size: 20px;
  }
  .popup-image {
    max-height: 250px;
  }
}
