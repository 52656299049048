.slide-image {
    height: 100px; /* Default height for larger screens */
}

@media (max-width: 768px) {
    .slide-image {
        height: 200px; /* Adjusted height for medium screens */
    }
}

@media (max-width: 480px) {
    .slide-image {
        height: 200px; /* Adjusted height for smaller screens */
    }
}
/* Add these classes for different heights */
.slide-image {
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    animation: slideAnimation 5s forwards;
    animation-direction: alternate;
  }
  
  .slide-image.height-900 {
    height: 600px;
  }
  
  .slide-image.height-600 {
    height: 600px;
  }
  
  .slide-image.height-300 {
    height: 300px;
  }
  
  .slide-image.height-200 {
    height: 200px;
  }
  