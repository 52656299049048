.logout-toast {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  padding: 15px 25px;
  border-radius: 5px;
  font-size: 18px;
  z-index: 9999;
}


/* Add your existing CSS here */

.mobile-only {
display: none;
}

@media (max-width: 768px) {
.mobile-only {
  display: block;
  margin-left: -90px;
  width: 310px;
  margin-top: 15px;
}

.phone-number a {
  color: #fdfdfd;
  text-decoration: none;
  font-size: 14px;
}
}
