/* DropdownExample.css */

  
  /* Style for labels */
  label {
    margin-right: 10px; /* Add some space between label and dropdown */
    font-weight: bold; /* Making label text bold */
    font-size: 26px; /* Increase font size */
  }
  
  /* Style for dropdowns */
  select {
    padding: 8px;
    font-size: 16px;
    border: 1px solid #6e8cb6; /* Changing border color */
    border-radius: 5px;
    width: 200px;
    background-color: #f2f2f2; /* Changing background color */
    margin-right: 50px; /* Add space between dropdowns */
  }
  
  select:focus {
    outline: none;
    border-color: #405f91; /* Changing border color on focus */
    box-shadow: 0 0 5px rgba(64, 95, 145, 0.5); /* Changing box shadow on focus */
  }
  
  .highlight-text{
     font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }