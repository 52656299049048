.cookie-modal {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
    padding: 10px 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    max-width: 600px;
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .cookie-modal-content {
    display: flex;
    align-items: center;
  }
  
  .cookie-modal-content p {
    margin: 0;
    margin-right: 20px;
  }
  
  .cookie-modal-content .button-container {
    display: flex;
  }
  
  .cookie-modal-content button {
    margin-left: 10px;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    background-color: #0554f2;
    color: white;
    cursor: pointer;
  }
  
  .cookie-modal-content button:hover {
    background-color: #0443c1;
  }