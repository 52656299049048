.projects-container {
    text-align: center;
    padding: 20px;
  }
  
  .projects-title {
    font-size: 2em;
    margin-bottom: 10px;
  }
  
  .projects-subtitle {
    font-size: 1.2em;
    margin-bottom: 20px;
  }
  
  .projects-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
  }
  
  .project-card {
    position: relative;
    border: 1px solid #ddd;
    border-radius: 5px;
    overflow: hidden;
    padding: 15px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  }
  
  .project-image {
    width: 100%;
    height: auto;
    border-radius: 5px;
  }
  
  .eye-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 50%;
    padding: 5px;
    font-size: 1.2em;
    color: #007bff;
    text-decoration: none;
  }
  
  .eye-icon:hover {
    background-color: rgba(255, 255, 255, 1);
  }
  
  .project-title {
    font-size: 1.5em;
    margin-top: 10px;
  }
  
  .project-description {
    font-size: 1em;
    color: #666;
  }
  