
.invoice-display-wrapper {
    font-family: Arial, sans-serif;
    max-width: 210mm;
    margin: 0 auto;
    background-color: white;
    border: 1px solid #000;
  }
  
  .invoice-display-header {
    background-color: #0066cc;
    color: white;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .invoice-display-logo img {
    height: 50px;
    width: auto;
  }
  
  .invoice-display-company-info {
    text-align:justify;
    font-size: 10px;
    line-height: 1.2;
  }
  
  .invoice-display-company-info h3 {
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: bold;
  }
  
  .invoice-display-company-info p {
    margin: 0;
  }
  
  .invoice-display-title h2 {
    font-size: 24px;
    margin: 0;
    color:white;
    margin-top: 80px;
  }
  
  
  
  /* @media screen and (max-width:"568px")
   {
    .invoice-display-title{
      font-size: 24px;
      margin: 0;
      color:white;
      margin-top: 80px;
    
    } */
    
  
  .invoice-display-content {
    padding: 20px;
  }
  
  .invoice-display-details {
    display: grid;
    grid-template-columns: auto;
    gap: 10px;
    margin-bottom: 20px;
    font-size: 10px;
  }
  
  .invoice-display-details p {
    margin: 0;
    display: flex;
    justify-content: space-between;
  }
  
  .invoice-display-details .field-value {
    flex-grow: 1;
    margin-left: 10px;
  }
  
  .invoice-display-address-details {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    font-size: 10px;
  }
  
  .invoice-display-bill-to, .invoice-display-ship-to {
    width: 48%;
    border: 1px solid #000;
    padding: 10px;
    min-height: 100px;
  }
  
  .invoice-display-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    font-size: 10px;
  }
  
  .invoice-display-table th, .invoice-display-table td {
    border: 1px solid #000;
    padding: 5px;
    text-align: left;
  }
  
  .invoice-display-summary {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    font-size: 10px;
  }
  
  .invoice-display-total-in-words {
    width: 60%;
    border: 1px solid #000;
    padding: 5px;
  }
  
  .invoice-display-totals {
    width: 35%;
    text-align: right;
  }
  
  .invoice-display-totals p {
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 2px 0;
  }
  
  .invoice-display-totals .total {
    font-weight: bold;
    border-top: 1px solid #000;
  }
  
  .invoice-display-balance-due {
    text-align: right;
    font-size: 10px;
    margin-bottom: 20px;
    padding: 2px 0;
  }
  
  .invoice-display-thank-you {
    margin: 20px 0;
    font-style: italic;
    font-size: 10px;
  }
  
  .invoice-display-signature {
    text-align: right;
    margin: 40px 0 20px;
    font-size: 10px;
    border-top: 1px solid #000;
    padding-top: 5px;
  }
  
  .invoice-display-terms, .invoice-display-bank-details {
    margin-bottom: 20px;
    font-size: 10px;
  }
  
  .invoice-display-terms h4, .invoice-display-bank-details h4 {
    margin-bottom: 5px;
    font-size: 12px;
    font-weight: bold;
  }
  
  .invoice-display-footer {
    text-align: center;
    padding: 10px;
    font-size: 10px;
    border-top: 1px solid #000;
  }
  
  .invoice-display-download-button {
    text-align: center;
    margin-top: 20px;
    width: 400px;
   
  }
  
  .invoice-display-download-button button {
    background-color: #000080;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    font-size: 14px;
    
  }
  
  .invoice-display-download-button button:hover {
    background-color: #000066;
  }
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  /* .invoice-display-wrapper {
    font-family: Arial, sans-serif;
    max-width: 210mm;
    margin: 0 auto;
    background-color: white;
    border: 1px solid #000;
  }
  
  .invoice-display-header {
    background-color:#0554f2;
    color: white;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .invoice-display-logo img {
    height: 50px;
    width: 120px;
    width: auto;
  }
  
  .invoice-display-company-info {
    text-align: right;
    font-size: 10px;
    line-height: 1.2;
  }
  
  .invoice-display-company-info h3 {
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: bold;
  }
  
  .invoice-display-company-info p {
    margin: 0;
  }
  
  .invoice-display-title h2 {
    font-size: 24px;
    margin: 0;
  }
  
  .invoice-display-content {
    padding: 20px;
  }
  
  .invoice-display-details {
    display: grid;
    grid-template-columns: auto;
    gap: 10px;
    margin-bottom: 20px;
    font-size: 10px;
  }
  
  .invoice-display-details p {
    margin: 0;
    display: flex;
    justify-content: space-between;
  }
  
  .invoice-display-details .field-value {
    border-bottom: 1px solid #000;
    flex-grow: 1;
    margin-left: 10px;
  }
  
  .invoice-display-address-details {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    font-size: 10px;
  }
  
  .invoice-display-bill-to, .invoice-display-ship-to {
    width: 48%;
    border: 1px solid #000;
    padding: 5px;
  }
  
  .invoice-display-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    font-size: 10px;
  }
  
  .invoice-display-table th, .invoice-display-table td {
    border: 1px solid #000;
    padding: 5px;
    text-align: left;
  }
  
  .invoice-display-summary {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    font-size: 10px;
  }
  
  .invoice-display-total-in-words {
    width: 60%;
    border: 1px solid #000;
    padding: 5px;
  }
  
  .invoice-display-totals {
    width: 35%;
    text-align: right;
  }
  
  .invoice-display-totals p {
    display: flex;
    justify-content: space-between;
    margin: 0;
    border-bottom: 1px solid #000;
    padding: 2px 0;
  }
  
  .invoice-display-totals .total {
    font-weight: bold;
  }
  
  .invoice-display-balance-due {
    text-align: right;
    font-size: 10px;
    margin-bottom: 20px;
    border-bottom: 1px solid #000;
    padding: 2px 0;
  }
  
  .invoice-display-thank-you {
    margin: 20px 0;
    font-style: italic;
    font-size: 10px;
  }
  
  .invoice-display-signature {
    text-align: right;
    margin: 40px 0 20px;
    font-size: 10px;
    border-top: 1px solid #000;
    padding-top: 5px;
  }
  
  .invoice-display-terms, .invoice-display-bank-details {
    margin-bottom: 20px;
    font-size: 10px;
  }
  
  .invoice-display-terms h4, .invoice-display-bank-details h4 {
    margin-bottom: 5px;
    font-size: 12px;
    font-weight: bold;
  }
  
  .invoice-display-footer {
    text-align: center;
    padding: 10px;
    font-size: 10px;
    border-top: 1px solid #000;
  }
  
  .invoice-display-download-button {
    text-align: center;
    margin-top: 20px;
  }
  
  .invoice-display-download-button button {
    background-color: #000080;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    font-size: 14px;
  }
  
  .invoice-display-download-button button:hover {
    background-color: #000066;
  }
  
   */
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  /* .invoice-display-wrapper {
    font-family: Arial, sans-serif;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: white;
    border: 1px solid #000;
  }
  
  .invoice-display-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #000;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
  
  .invoice-display-logo img {
    height: 80px;
    width: auto;
  }
  
  .invoice-display-company-info {
    text-align: right;
    font-size: 12px;
  }
  
  .invoice-display-company-info h3 {
    margin-bottom: 5px;
    font-size: 16px;
  }
  
  .invoice-display-company-info p {
    margin: 2px 0;
  }
  
  .invoice-display-title {
    text-align: center;
    font-size: 24px;
    margin: 20px 0;
    text-transform: uppercase;
  }
  
  .invoice-display-details {
    display: grid;
    grid-template-columns: auto auto;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .invoice-display-details p {
    margin: 0;
    border-bottom: 1px solid #ccc;
    padding: 5px 0;
  }
  
  .invoice-display-address-details {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .invoice-display-bill-to, .invoice-display-ship-to {
    width: 48%;
    border: 1px solid #000;
    padding: 10px;
  }
  
  .invoice-display-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .invoice-display-table th, .invoice-display-table td {
    border: 1px solid #000;
    padding: 8px;
    text-align: left;
  }
  
  .invoice-display-table th {
    background-color: #f2f2f2;
  }
  
  .invoice-display-summary {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .invoice-display-total-in-words {
    width: 60%;
    border: 1px solid #000;
    padding: 10px;
  }
  
  .invoice-display-totals {
    width: 35%;
  }
  
  .invoice-display-totals p {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    padding: 5px 0;
    margin: 0;
  }
  
  .invoice-display-thank-you {
    margin: 20px 0;
    font-style: italic;
  }
  
  .invoice-display-signature {
    text-align: right;
    margin: 40px 0 20px;
    border-top: 1px solid #000;
    padding-top: 10px;
  }
  
  .invoice-display-terms, .invoice-display-bank-details {
    margin-bottom: 20px;
  }
  
  .invoice-display-terms h4, .invoice-display-bank-details h4 {
    margin-bottom: 10px;
  }
  
  .invoice-display-footer {
    text-align: center;
    padding: 10px;
    border-top: 1px solid #000;
    font-size: 12px;
  }
  
  .invoice-display-download-button {
    text-align: center;
    margin-top: 20px;
  }
  
  .invoice-display-download-button button {
    background-color: #0066cc;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    font-size: 16px;
  }
  
  .invoice-display-download-button button:hover {
    background-color: #0056b3;
  } */
  
  
  
  
  /* 
  
  .invoice-wrapper {
    background-color: #f0f0f0;
    padding: 20px;
  }
  
  .invoice-form {
    font-family: Arial, sans-serif;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: white;
    border: 1px solid #000;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-bottom: 2px solid #000;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
  
  .logo img {
    height: 100px;
    width: 170px;
    margin-top: 50px;
  }
  
  .company-info {
    text-align: right;
  }
  
  .company-info h3 {
    margin-bottom: 5px;
  }
  
  .company-info p {
    margin: 2px 0;
    font-size: 14px;
  }
  
  h2 {
    color: #0066cc;
    text-align: center;
    margin: 20px 0;
    font-size: 24px;
  }
  
  .invoice-details {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
  
  .invoice-details p {
    display: flex;
    justify-content: space-between;
    margin: 5px 0;
    border-bottom: 1px solid #ccc;
  }
  
  .invoice-details p strong {
    min-width: 100px;
  }
  
  .address-details {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .bill-to, .ship-to {
    width: 48%;
    border: 1px solid #000;
    padding: 10px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  th, td {
    border: 1px solid #000;
    padding: 8px;
    text-align: left;
  }
  
  th {
    background-color: #f2f2f2;
  }
  
  .invoice-summary {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .total-in-words {
    width: 60%;
    border: 1px solid #000;
    padding: 10px;
  }
  
  .totals {
    width: 35%;
  }
  
  .totals p {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    padding: 5px 0;
  }
  
  .thank-you-message {
    margin-bottom: 20px;
    border-top: 1px solid #000;
    padding-top: 10px;
  }
  
  .authorized-signature {
    text-align: right;
    margin: 40px 0 20px;
    border-top: 1px solid #000;
    padding-top: 10px;
  }
  
  .terms-conditions, .bank-details {
    margin-bottom: 20px;
  }
  
  .footer {
    text-align: center;
    padding: 10px;
    background-color: #0066cc;
    color: white;
  }
  
  .footer a {
    color: white;
  }  */