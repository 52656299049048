.trending-courses {
    text-align: center;
    margin: 40px;
    max-width: 1200px;
    margin: 0 auto;
}

.course-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* Each column will take equal width */
    gap: 20px;
    /* Gap between the grid items */
    margin-bottom: 30px;
    /* Add space between the second row and footer */
}

.contact-us-section-wrapper{
  margin-top: -30px;
}
.resposnive_allprogram{
    margin-left: 250px;
}
@media screen and (max-width: 450px){
    .course-list {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        /* Each column will take equal width */
        gap: 20px;
        /* Gap between the grid items */
        margin: 30px;
        /* Add space between the second row and footer */
    }
    .resposnive_allprogram{
        margin-left: 60px;
    }
}

.course-card {
    background-color: #c7e1ef;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(46, 142, 177, 0.1);
    height: 100%;
    /* Ensure equal height for all cards */
}

.course-card img {
    width: 100%;
    max-height: 150px;
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 10px;
}

.course-card h3 {
    font-size: 1.2rem;
    margin-bottom: 10px;
}

.course-card p {
    font-size: 1rem;
    margin-bottom: 15px;
}

.course-card a {
    display: block;
    
    color: #fff;
    text-decoration: none;
    padding: 8px 16px;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.course-card a:hover {
    background-color: #0056b3;
}

.footer {
    background-color: #f0f0f0;
    padding: 20px;
    text-align: center;
}


.button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    border: none;
    border-radius: 4px;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    width: 210px;
  }
  
  .button:hover {
   
    transform: scale(1.05);
  }
  
  .button:active {
   
    transform: scale(1);
  }
  






/* 



body {
    font-family: 'Open Sans', sans-serif;
    margin: 0;
    padding: 0;
    color: #333;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
  }
  
  .section-title-wrap {
    text-align: center;
    padding: 20px 0;
  }
  
  .section-title-wrap .heading {
    font-size: 21px;
    color: #00aaff;
  }
  
  .form-container {
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .form-container .form-group {
    margin-bottom: 15px;
  }
  
  .form-container .form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .form-container .form-group input,
  .form-container .form-group select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .form-container .form-group .btn {
    background-color: #007bff;
    border-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border-radius: 4px;
  }
  
  .breadcrumb {
    background: #f8f9fa;
    padding: 10px 15px;
    margin-top: 20px;
    border-radius: 4px;
  }
  
  .breadcrumb-item a {
    color: #007bff;
    text-decoration: none;
  }
  
  .breadcrumb-item.active {
    color: #6c757d;
  }
  
  


  .about-banner-wrap {
    background-color: darkturquoise;
    height: 600px;
    display: flex;
    align-items: center;
    color: #fff;
  }
  
  .vision-overview {
    padding: 60px 0;
  }
  
  .item-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .item-container .res-image-container img {
    border-radius: 5px;
    margin-right: 20px;
  }
  
  .item-container .title {
    font-size: 18px;
    font-weight: bold;
  }
  
  .vision-content ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  .vision-content h2 {
    margin-top: 30px;
  }
  
  .contact-us-section-wrappaer {
    padding: 60px 0;
    background-color: #f8f9fa;
  }
  
  .contact-form-wrap img {
    width: 100%;
    border-radius: 8px;} 
  
 
  @media (max-width: 768px) {
    .form-container,
    .item-container {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .form-container .form-group .btn {
      width: 100%;
    }
  
    .item-container .res-image-container img {
      margin: 0 0 10px 0;
    }
  }
  

 











/* General Styles */
.vision-content {
    text-align: justify;
    font-family: 'Open Sans', sans-serif;
    padding-top: 20px;
    padding-right: 30px;
    padding-bottom: 20px;
    padding-left: 30px;
  }
  
  /* Heading Styles */
  .heading {
    font-size: 41px;
    color: #0c0c0c; /* Adjust as needed */
    padding-top: 10px;
    padding-right: 15px;
    padding-bottom: 10px;
    padding-left: 15px;
    text-align: center;
  }
  
  /* List Styles */
  .vision-content ul {
    font-size: 15px;
    padding-left: 20px;
    padding-top: 10px;
    padding-right: 15px;
    padding-bottom: 10px;
  }
  
  /* Paragraph Styles */
  .vision-content p {
    line-height: 1.6;
    margin-bottom: 20px;
    padding-top: 5px;
    padding-right: 10px;
    padding-bottom: 5px;
    padding-left: 10px;
  }
  
  /* Custom Styles */
  .text-color-primary {
    color: #0a0a0a; /* Adjust as needed */
    font-size: 18px;
    padding-top: 5px;
    padding-right: 10px;
    padding-bottom: 5px;
    padding-left: 10px;
    margin-bottom: 10px;
    margin-top: -20px;
  }
  
  /* Mobile View Styles */
  @media (max-width: 768px) {
    .vision-content {
      padding-top: 10px;
      padding-right: 15px;
      padding-bottom: 10px;
      padding-left: 15px;
    }
    
    .heading {
      font-size: 18px;
      padding-top: 5px;
      padding-right: 10px;
      padding-bottom: 5px;
      padding-left: 10px;
      margin-bottom: 20px;
      margin-top: -30px;
    }
  
    .text-color-primary{
        font-size: 18px;
        padding-top: 5px;
        padding-right: 10px;
        padding-bottom: 5px;
        padding-left: 10px;
        margin-bottom: 10px;
        margin-top: 0px;
    }
    .vision-content ul {
      font-size: 14px;
      padding-left: 10px;
      padding-top: 5px;
      padding-right: 10px;
      padding-bottom: 5px;
    }
  
    .vision-content p {
      line-height: 1.4;
      margin-bottom: 15px;
      padding-top: 5px;
      padding-right: 10px;
      padding-bottom: 5px;
      padding-left: 10px;
    }
  }
  /* Media Queries for Mobile */
/* @media (max-width: 768px) {
    .about-banner-wrap {
      height: auto;
      padding: 20px 0;
    }
  
    .section-space--pt_100 {
      padding-top: 20px;
    }
  
    .banner-title {
      font-size: 1.5rem;
    }
  
    .banner-description {
      font-size: 1rem;
    }
  
    .breadcrumb-nav {
      margin-top: 10px;
      width:200px;
    }
  } */

  /* Default styles */
  .about-banner-wrap {

    height: 1500px;
    display: flex;
    background-size: cover;
    align-items: center;
    background-image: url('../../../Trainingimage/educat.jpg');
  }
  
  .banner-title, .banner-description {
    color: #fff;
  }
  
  .breadcrumb-nav {
    margin-top: 20px;
    color: #fff; 
    
    /* Adjust color for breadcrumb */
  }
  
  /* .form-container {
    background-color: #fff;
    padding: 30px;
    border-radius: 8px;
    max-height: 390px;
    width: 450px;
    margin-right: 120px;
  }
  
  .form-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  
  .form-group {
    margin-bottom: 15px;
    padding-right: 15px;
    padding-left: 15px;
  }
  
  .form-group label {
    margin-bottom: 5px;
    color: #0c0c0c;
  }
   */
  /* Media Queries for Mobile */
  /* @media (max-width: 768px) {
    .about-banner-wrap {
      height: auto;
      padding: 20px 0;
    }
  
    .section-space--pt_100 {
      padding-top: 20px;
    }
  
    .banner-title {
      font-size: 1.5rem;
    }
  
    .banner-description {
      font-size: 1rem;
    }
  
    .breadcrumb-nav {
      margin-top: 10px;
    }
  
    .form-container {
      padding: 20px;
      max-height: none;
      width: auto;
      margin-right: 0;
    }
  
    .form-row {
      flex-direction: column;
      margin-right: 0;
      margin-left: 0;
    }
  
    .form-group {
      margin-bottom: 10px;
      padding-right: 0;
      padding-left: 0;
    }
  
    .form-group.col-md-6 {
      width: 100%;
    }
  }
   */



   .about-banner-wrap {
    background-image: url('../../../Trainingimage/educat.jpg');
    height: 500px;
    display: flex;
    align-items: center;
    flex-direction: column; /* Center content vertically */
  }
  
  .banner-title, .banner-description {
    color: #fff;
  }
  
  .breadcrumb-nav {
    margin-top: 20px;
    color: #fff; /* Adjust color for breadcrumb */
    display: flex;
    flex-wrap: wrap; /* Ensure text wraps within the container */
    justify-content: center; /* Center breadcrumb text */
    text-align: center; /* Center breadcrumb text */
    width: 100%; /* Ensure breadcrumb occupies full width */
  }
  
  .breadcrumb-nav a {
    color: #fff;
    text-decoration: none;
    padding: 0 5px;
  }
  
  .form-container {
    background-color: #fff;
    padding: 30px;
    border-radius: 8px;
    max-height: 390px;
    width: 450px;
    margin-right: 120px;
  }
  
  .form-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  
  .form-group {
    margin-bottom: 15px;
    padding-right: 15px;
    padding-left: 15px;
  }
  
  .form-group label {
    margin-bottom: 5px;
    color: #0c0c0c;
  }
  
  /* Media Queries for Mobile */
  @media (max-width: 768px) {
    .about-banner-wrap {
      height: auto;
      padding: 20px 0;
      align-items: flex-start; /* Align content to the top */
    }
  
    .section-space--pt_100 {
      padding-top: 20px;
    }
  
    .banner-title {
      font-size: 1.5rem;
    }
  
    .banner-description {
      font-size: 1rem;
    }
  
    .breadcrumb-nav {
      margin-top: 10px;
    }
  
    .form-container {
      padding: 20px;
      max-height: none;
      width: auto;
      margin-right: 0;
    }
  
    .form-row {
      flex-direction: column;
      margin-right: 0;
      margin-left: 0;
    }
  
    .form-group {
      margin-bottom: 10px;
      padding-right: 0;
      padding-left: 0;
    }
  
    .form-group.col-md-6 {
      width: 100%;
    }
  }
  