/* CSS */
.card-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
}

.card {
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

.info-container {
    display: grid;
    grid-template-columns: 120px 1fr;

}

.label {
    text-align: right;
    font-weight: bold;
}

.button-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px 20px;
}

.button-container input[type="date"],
.button-container button {
    padding: 8px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.button-container input[type="date"] {
    margin-right: 10px;
}

.button-container button {
    background-color: #007bff;
    color: white;
}

.button-container button:hover {
    background-color: #0056b3;
}






