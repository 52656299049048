.tp-about-info-item-icon {
    font-size: 60px;
    color: var(--tp-theme-primary);
    flex: 0 0 auto;
    margin-right: 30px;
}

.tp-about-thumb {
    position: relative;
    margin-left: -45px;
    margin-bottom: -40px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .tp-about-thumb {
        margin-left: 0px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .tp-about-thumb {
        margin-bottom: 50px;
    }
}

@media (max-width: 767px) {
    .tp-about-thumb {
        margin-left: 0;
        margin-bottom: 70px;
    }
}

.tp-about-thumb-main {
    display: inline-block;
}

.tp-about-thumb-shape-one .about-image-1 {
    position: absolute;
    top: -40px;
    right: 55px;
    z-index: 9;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .tp-about-thumb-shape-one .about-image-1 {
        display: none;
    }
}

@media (max-width: 767px) {
    .tp-about-thumb-shape-one .about-image-1 {
        position: static;
    }
}

.tp-about-thumb-shape-two {
    position: absolute;
    top: -115px;
    right: 320px;
    z-index: -1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .tp-about-thumb-shape-two {
        top: -35px;
        right: 180px;
    }
}

@media (max-width: 767px) {
    .tp-about-thumb-shape-two {
        top: -35px;
        right: 70px;
    }
}

.tp-about-exprience {
    position: absolute;
    display: inline-block;
    bottom: -76px;
    right: 15px;
}

.tp-about-exprience-title {
    font-size: 80px;
    font-weight: 700;
    color: var(--tp-theme-secondary);
}

.tp-about-exprience p {
    font-size: 16px;
    font-weight: 500;
    color: var(--tp-theme-primary);
    text-align: center;
    border: 2px solid rgb(119, 113, 129);
    display: inline-block;
    border-radius: 50%;
    padding: 34px 75px 42px 75px;
    background-color: var(--tp-common-white);
    transform: translateY(-48px) rotate(-24deg);
}

.tp-about-avatar-thumb {
    flex: 0 0 auto;
    margin-right: 24px;
}

.tp-about-avatar-thumb img {
    height: 60px;
    width: 60px;
    border-radius: 50% !important;
    object-fit: cover;
    border: 1px solid rgb(15, 13, 29) !important;
}

.tp-about-avatar-title {
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 2px;
  
}

.tp-about-avatar-info span {
    font-size: 14px;
    color: var(--tp-text-1);
}

@media (max-width: 767px) {
    .tp-about-btn {
        margin-bottom: 30px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .tp-about-btn {
        margin-bottom: 0px;
    }
}

@media (max-width: 767px) {
    .tp-about-wrap {
        margin-left: 0;
    }
}

.tp-about-two {
    position: relative;
    margin-top: 100px;
}

.tp-about-two-wrap {
    position: relative;
}

@media (max-width: 767px) {
    .tp-about-two-thumb>img {
        height: 400px;
        width: 100%;
        object-fit: cover;
    }
}

.tp-about-two-thumb-shape {
    position: absolute;
    bottom: 0;
    right: 0;
    border: 10px solid #fff;
    border-bottom: none;
}

@media (max-width: 767px) {
    .tp-about-two-thumb-shape {
        position: static;
    }
}

.tp-about-two-thumb-shape-two {
    position: absolute;
    top: 110px;
    right: 120px;
    animation: tpxy 3s infinite linear alternate;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .tp-about-two-thumb-shape-two {
        right: 0;
    }
}

.tp-about-two-shape-3 {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.tp-about-two-shape-4 {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
}

.tp-about-two-list {
    display: inline-block;
    border-bottom: 1px solid rgba(15, 13, 29, 0.059);
    padding-bottom: 32px;
    margin-bottom: 40px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .tp-about-two-list {
        padding-bottom: 24px;
        margin-bottom: 24px;
    }
}

.tp-about-two-list li {
    list-style: none;
    float: left;
    width: 50%;
    color: var(--tp-theme-secondary);
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 13px;
    display: flex;
    align-items: center;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .tp-about-two-list li {
        width: 100%;
        font-size: 14px;
        margin-bottom: 6px;
    }
}

@media (max-width: 767px) {
    .tp-about-two-list li {
        width: 100%;
    }
}

.tp-about-two-list li span {
    display: inline-block;
    margin-right: 6px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .tp-about-details .tp-about-btn {
        margin-right: 20px;
    }
}

.tp-about-3-item {
    display: flex;
}

@media (max-width: 767px) {
    .tp-about-3-item {
        flex-wrap: wrap;
    }
}

.tp-about-3-item-icon {
    margin-right: 30px;
}

@media (max-width: 767px) {
    .tp-about-3-item-icon {
        margin-right: 0px;
        margin-bottom: 30px;
    }
}

.tp-about-3-item-icon i {
    font-size: 65px;
    color: var(--tp-theme-primary);
    background-color: rgb(242, 244, 248);
    width: 90px;
    height: 90px;
    line-height: 107px;
    display: inline-block;
    text-align: center;
}

.tp-about-3-item-title {
    font-size: 20px;
}

.tp-about-3-item-content p {
    font-size: 16px;
}

@media (max-width: 767px) {
    .tp-about-3-item-content p br {
        display: none;
    }
}

.tp-about-3-list {
    list-style: none;
}

.tp-about-3-list ul li {
    list-style: none;
    color: var(--tp-theme-secondary);
    font-size: 18px;
    margin-bottom: 18px;
    display: flex;
}

.tp-about-3-list ul li span {
    display: inline-block;
    margin-right: 23px;
}

.tp-about-3-btn {
    margin-right: 53px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
(max-width: 767px) {
    .tp-about-3-btn {
        margin-bottom: 15px;
        margin-right: 0;
    }
}

.tp-about-3-customar-title {
    font-weight: 800;
    font-size: 60px;
    margin-right: 19px;
}

.tp-about-3-thumb-1 {
    text-align: end;
}

.tp-about-3-wrap {
    position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .tp-about-3-wrap {
        margin-bottom: 60px;
    }
}

.tp-about-3-shape-1 {
    position: absolute;
    top: 34%;
    left: 0;
}

.tp-about-3-project {
    position: absolute;
    bottom: 63px;
    right: -43px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .tp-about-3-project {
        position: static;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .tp-about-3-project {
        right: 180px;
    }
}

@media (max-width: 767px) {
    .tp-about-3-project {
        position: static;
    }
}

.tp-about-3-project span {
    display: inline-block;
    font-size: 16px;
    color: var(--tp-text-1);
    background-color: var(--tp-common-white);
    padding: 7px 22px;
    margin-bottom: 19px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .tp-about-3-project span {
        margin-bottom: 0;
    }
}

.tp-about-3-project-title {
    font-size: 50px;
    font-weight: 800;
    color: var(--tp-theme-primary);
    transform: translateX(50px) rotate(-90deg);
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
(max-width: 767px) {
    .tp-about-3-project-title {
        transform: translateX(22px) rotate(0deg);
        margin-right: 50px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
(max-width: 767px) {
    .tp-about-3-details {
        flex-wrap: wrap;
    }
}

.tp-about-3-thumb-1 img {
    max-width: 100%;
}

.tp-about-3-thumb-2 img {
    max-width: 100%;
}

.tp-about-4-thumb img {
    max-width: 100%;
}

.tp-about-4 .tp-about-info-item {
    padding: 28px 18px 6px 28px;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

@media (max-width: 767px) {
    .tp-about-4 .tp-about-info-item {
        flex-wrap: wrap;
    }
}

.tp-about-4 .tp-about-info-item:hover {
    box-shadow: 0px 2px 40px 0px rgba(16, 23, 40, 0.06);
}

.tp-about-4 .tp-about-info-item-icon {
    color: var(--tp-theme-3);
}

@media (max-width: 767px) {
    .tp-about-4 .tp-about-info-item-icon {
        margin-right: 0;
        margin-bottom: 30px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .tp-about-4 .tp-about-info-item-icon {
        margin-right: 30px;
    }
}

.tp-about-4 .tp-about-avatar-thumb img {
    border-color: var(--tp-theme-3) !important;
}

.overlay-anim {
    position: relative;
}

.overlay-anim:hover::after {
    height: 100%;
    opacity: 0;
    -webkit-transition: all 400ms linear;
    transition: all 400ms linear;
}

.overlay-anim::after {
    background: rgba(255, 255, 255, 0.3);
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 0;
    opacity: 1;
    z-index: 9;
    pointer-events: none;
}

@media (max-width: 550px){
    .responsive_aboutus{
        margin-top: 40px;
    }
}


@media (min-width: 551px) {
    .responsive_aboutus{
        margin-top: 40px;
        margin-left: 100px;
    }
}

@media (min-width: 769px){
    .responsive_aboutus{
        margin-left: 100px;
        margin-top: 40px;
    }
}