.invoice-form {
    font-family: Arial, sans-serif;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
  }
  
  .header {
    background-color: #0066cc;
    color: white;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .company-info {
    margin-bottom: 20px;
  }
  
  .invoice-details {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  th {
    background-color: #f2f2f2;
  }
  
  input, textarea {
    width: 100%;
    padding: 5px;
    margin-top: 5px;
  }
  
  button {
    background-color: #0066cc;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  .invoice-summary {
    margin-top: 20px;
  }
  
  .invoice-summary label {
    display: block;
    margin-bottom: 10px;
  }
  
  .thank-you-message, .terms-conditions, .bank-details {
    margin-top: 20px;
  }
  
  .terms-conditions h4, .bank-details h4 {
    margin-bottom: 10px;
  }


  