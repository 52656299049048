.homeThree__footer .footer-top {
    background-color: #0b0c16;
    padding: 43px 0;
}

.homeThree__footer .footer-top .heading-2 {
    color: white;
}

.homeThree__footer .footer-top .email-wrapper {
    max-width: 430px;
    width: 100%;
    margin-right: 20px;
}

.homeThree__footer .footer-top input {
    background: rgba(255, 255, 255, 0.3);
    color: white;
    width: 100%;
    padding: 0 20px;
    border: none;
    outline: none;
    border-radius: 5px;
    height: 60px;
}

.homeThree__footer .footer-top .subscribe-btn {
    width: 175px;
    height: 60px;
    border-radius: 5px;
    background: #ffaa17;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 767px) {
    .homeThree__footer .footer-top .heading-2 {
        margin-right: 0;
    }
    .homeThree__footer .footer-top .subscribe-btn {
        width: 175px;
        height: 45px;
    }
    .homeThree__footer .footer-top input {
        height: 45px;
    }
    .homeThree__footer .footer-top .email-wrapper {
        margin-right: 0px;
        margin-bottom: 10px;
    }
}

.homeThree__footer .footer-body .about-company {
    margin-top: -100px;
    background: white;
    border-radius: 5px;
    max-width: 350px;
    width: 100%;
    overflow: hidden;
}

.homeThree__footer .footer-body .about-company .logo-wrapper {
    background-color: #ffaa17;
    padding: 23px 54px;
    position: relative;
    margin-bottom: 77px;
}

.homeThree__footer .footer-body .about-company .logo-wrapper img {
    max-width: 235px;
    width: 100%;
}

.homeThree__footer .footer-body .about-company .logo-wrapper:after {
    content: "";
    width: 40px;
    height: 40px;
    position: absolute;
    bottom: -20px;
    left: 20%;
    rotate: 45deg;
    background-color: #ffaa17;
}

@media (max-width: 575px) {
    .homeThree__footer .footer-body .about-company .logo-wrapper:after {
        width: 30px;
        height: 30px;
        bottom: -15px;
    }
}

@media (max-width: 575px) {
    .homeThree__footer .footer-body .about-company .logo-wrapper {
        margin-bottom: 30px;
        padding: 15px 65px;
    }
}

.homeThree__footer .footer-body .about-company .content {
    padding: 0 24px 58px 24px;
}

.homeThree__footer .footer-body .about-company .content .socials ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    gap: 15px;
    margin-top: 26px;
}

.homeThree__footer .footer-body .about-company .content .socials ul li {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    border: 1px solid #ffaa17;
    transition: 0.3s ease;
    cursor: pointer;
}

.homeThree__footer .footer-body .about-company .content .socials ul li a {
    color: #ffaa17;
}

.homeThree__footer .footer-body .about-company .content .socials ul li:hover {
    background: #ffaa17;
}

.homeThree__footer .footer-body .about-company .content .socials ul li:hover a {
    color: white;
}

.homeThree__footer .footer-body .about-company .content .socials ul li.active {
    background: #ffaa17;
}

.homeThree__footer .footer-body .about-company .content .socials ul li.active a {
    color: white;
}

@media (max-width: 1199px) {
    .homeThree__footer .footer-body .about-company {
        margin-top: 0px;
        max-width: 400px;
    }
}

.homeThree__footer .footer-body .wrapper {
    padding-top: 100px;
}

.homeThree__footer .footer-body .wrapper .heading-4 {
    color: white;
    font-size: 24px;
    margin-bottom: 38px;
}

.homeThree__footer .footer-body .wrapper ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.homeThree__footer .footer-body .wrapper ul li {
    margin-bottom: 10px;
}

.homeThree__footer .footer-body .wrapper ul li a {
    text-decoration: none;
    color: white;
    font-weight: 300;
    transition: 0.3s ease;
}

.homeThree__footer .footer-body .wrapper ul li:hover a {
    color: #ffaa17;
    text-decoration: underline !important;
}

.homeThree__footer .footer-body .wrapper ul li.active a {
    color: #ffaa17;
    text-decoration: underline !important;
}

.homeThree__footer .footer-body .wrapper .news {
    margin-bottom: 20px;
}

.homeThree__footer .footer-body .wrapper .news .img-wrapper {
    width: 90px;
    height: 90px;
    border-radius: 5px;
    overflow: hidden;
    margin-right: 20px;
}

.homeThree__footer .footer-body .wrapper .news .img-wrapper img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    transition: 0.5s ease;
}

.homeThree__footer .footer-body .wrapper .news .paragraph {
    color: white;
    transition: 0.3s ease;
}

.homeThree__footer .footer-body .wrapper .news .info {
    width: calc(100% - 100px);
}

.homeThree__footer .footer-body .wrapper .news .info .date {
    color: #ffaa17;
}

.homeThree__footer .footer-body .wrapper .news:hover .paragraph {
    color: #ffaa17;
}

.homeThree__footer .footer-body .wrapper .news:hover img {
    scale: 1.1;
}

.homeThree__footer .footer-body .wrapper .contact {
    margin-bottom: 20px;
}

.homeThree__footer .footer-body .wrapper .contact .icon-wrapper {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: 15px;
    background-color: white;
    color: #ffaa17;
    cursor: pointer;
    transition: 0.3s ease;
}

.homeThree__footer .footer-body .wrapper .contact .icon-wrapper:hover {
    background-color: #ffaa17;
    color: white;
}

.homeThree__footer .footer-body .wrapper .contact .icon-wrapper.active {
    background-color: #ffaa17;
    color: white;
}

.homeThree__footer .footer-body .wrapper .contact .paragraph {
    color: white;
}

@media (max-width: 1199px) {
    .homeThree__footer .footer-body .wrapper {
        padding-top: 50px;
    }
}

.homeThree__footer .footer-body .divider {
    border: 1px solid rgba(5, 84, 242, 0.4);
    margin-top: 73px;
}

.homeThree__footer .footer-body .footer-bottom {
    padding: 20px 0;
}

.homeThree__footer .footer-body .footer-bottom .paragraph {
    color: white;
}

.homeThree__footer .footer-body .footer-bottom a {
    color: white;
}

.homeThree__footer .footer-body .footer-bottom ul {
    gap: 10px;
    margin: 0;
    padding: 0;
    list-style: none;
}

.homeThree__footer .footer-body .footer-bottom #scroll-to-top {
    opacity: 0;
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #ffaa17;
    color: white;
    border: none;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    cursor: pointer;
    z-index: 500;
    transition: opacity 0.3s ease;
}

.homeThree__footer .footer-body .footer-bottom #scroll-to-top.show {
    opacity: 1;
    /* Make the button visible */
}

@media (max-width: 767px) {
    .homeThree__footer .footer-body .footer-bottom #scroll-to-top {
        right: 5px;
        width: 40px;
        height: 40px;
        opacity: 0.7 !important;
    }
}