

.w3ls-cource-first {
    background: #F9F9F9;
    box-shadow: -1px 3px 5px 1px rgba(0, 0, 0, 0.15);
}

.w3ls-cource-first ul li i {
    color: #42a5f5;
}

.w3ls-cource-first {
    border-top: 5px solid #f76565;
}

.agile-course-main-2 {
    margin-left: -5em;
}

.cource-list-agile {
    position: relative;
}

.buttons-w3ls {
    left: 26%;
}

.buttons-w3ls,
.buttons-w3ls-2 {
    position: absolute;
    bottom: -6%;
}

.buttons-w3ls a,
.buttons-w3ls-2 a {
    font-size: 15px;
    border-radius: 0;
    letter-spacing: 1px;
    padding: 10px 20px;
}

.buttons-w3ls a.button-cour-w3ls,
.buttons-w3ls-2 a.button-cour-w3ls {
    background: #42a5f5;
}

.agile-course-main-3 {
    margin-right: -5em;
    z-index: 99;
}

.buttons-w3ls-2 {
    right: 32%;
}

.cource-list-agile:nth-child(3) .w3ls-cource-first {
    border-top: 5px solid #42a5f5;
}

.cource-list-agile:nth-child(3) .w3ls-cource-first ul li i {
    color: #f76565;
}

.cource-list-agile-2 {
    margin: 5em 0;
}

.single-left1 p i {
    color: #42a5f5;
    display: block;
}

.admin {
    background: #424040;
}

.admin p {
    color: #f5f5f5;
    font-size: 14px;
    line-height: 2.4;
}

.admin p i {
    font-size: 28px;
    vertical-align: middle;
}

.admin a {
    display: inline-block;
    color: #fff;
    font-size: 18px;
    letter-spacing: 1px;
}

.admin a:hover {
    color: #fff;
}

/* comments */

.comments-grid-right ul li {
    display: inline-block;
    color: #888;
    font-size: 14px;
    letter-spacing: 1px;
}

.comments-grid-right ul li a {
    color: #42a5f5;
}

.comments-grid-right ul li a:hover {
    color: #555;
}

.comments-grid-right p {
    font-size: 14px;
}

/* //comments */

.leave-coment-form input[type="text"],
.leave-coment-form input[type="email"],
.leave-coment-form textarea {
    outline: none;
    border: 1px solid #000;
    background: none;
    padding: 12px;
    font-size: 15px;
    color: #212121;
    box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.15);
}

.leave-coment-form textarea {
    min-height: 200px;
    resize: none;
    width: 100%;
}

.mm_single_submit {
    text-align: right;
}

.leave-coment-form input[type="submit"] {
    outline: none;
    border: none;
    background: #42a5f5;
    padding: 14px 36px;
    font-size: 15px;
    color: #fff;
    letter-spacing: 2px;
    box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.14);
}

.leave-coment-form input[type="submit"]:hover {
    background: #212121;
}

.resposnive_python_button{
    margin-right: -450px;
}

@media screen and (max-width :568px) and (max-width: 768px) {
    .agile-course-main-2 {
        margin-left: 1px;
    }

    .resposnive_python_button{
        margin-right: -150px;
    }
    
}