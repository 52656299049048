@media screen and (max-width: 768px) {
    .resposnive_placement_container{
        margin-left: 140px;
    }

    .resposnive_placement_image{
        height: 155px;
    }

    .section-title-wrap-text-center{
                  margin-left: 260px;
    }

    
}


/* .col-lg-9-order-lg-2-order-2{

    margin-left: 320px;
} */

@media screen and (min-width: 768px) and (max-width: 1024px)
{

    .row{
        margin-right: 190px;
    }
}
   .tab-content-ht-tab__content{

    margin-left: 320px;
   }

   .section-title-wrap-text-center{
    margin-left: 260px;
}